<!--
    @name: InputItem
    @description：InputItem
    @author: ZengWei
    @date: 2022-03-16 16:41
-->
<template>
  <div>
    <component
      ref="widgetComponent"
      :is="getWidgetName(element)"
      :element="element"
      :disabled="disabled"
      :editFields="editFields"
      :formData="formData"
      :displayData="displayData"
      @trigger-active="triggerActive(arguments)"
    />
  </div>
</template>

<script>
import WidgetComponents from "@/components/formParser/NewParser/FormItem";
export default {
  name: "InputItem",
  components: {
    ...WidgetComponents
  },
  props: [
    'element','disabled','editFields', 'formData', 'displayData',
  ],
  data() {
    return {}
  },
  methods: {
    getWidgetName(widget) {
      return `widget-${widget.type}`
    },
    triggerActive(params){
      const compShowHide = params[0]; // 受控控件
      const ctrlType =  params[1] ?? '' // 交互类型 - 默认显示隐藏
      const optionData =  params[2] ?? ''// 级联数据

      this.$emit('trigger-active',compShowHide,ctrlType,optionData)
    },
    validateFormInput() {
      if(typeof this.$refs.widgetComponent.validateFormInput === 'function'){
        this.$refs.widgetComponent.validateFormInput()
      }
    },
    getChildFormData() {
      if(typeof this.$refs.widgetComponent.getChildFormData === 'function'){
        return this.$refs.widgetComponent.getChildFormData()
      }
    }
  },
}
</script>

<style scoped>

</style>
