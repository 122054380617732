<!--
    @name: widget-textarea
    @description：widget-textarea
    @author: ZengWei
    @date: 2022-03-25 09:28
-->
<template>
  <el-input
    v-model="element.value"
    :rows="3"
    type="textarea"
    :placeholder="element.config.placeholder"
    :maxlength="element.config.maxlength"
    :disabled="disabled || element.config.disabled"
  ></el-input>
</template>

<script>
import formItemMinix from "@/components/formParser/NewParser/scripts/formItemMinix";

export default {
  name: "WidgetTextarea",
  components: {},
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped>

</style>
