<!--
    @name: widget-date-range
    @description：widget-date-range
    @author: ZengWei
    @date: 2022-03-25 09:37
-->
<template>
  <el-date-picker
    v-model="element.value"
    :disabled="disabled || element.config.disabled"
    :type="element.config.type"
    :range-separator="element.config['range-separator']"
    :format="element.config.format"
    :placeholder="element.config.placeholder"
    :value-format="element.config['value-format']"
    style="width: 100%"
  >
  </el-date-picker>
</template>

<script>
import {DatePicker} from "element-ui";
import formItemMinix from "@/components/formParser/NewParser/scripts/formItemMinix";

export default {
  name: "WidgetDateRange",
  components: {
    'el-date-picker': DatePicker
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped>

</style>
