<!--
    @name: widget-bimList
    @description：widget-bimList
    @author: ZengWei
    @date: 2022-03-25 09:38
-->
<template>
  <ChooseModel
    ref="chooseModel"
    @set-data="setModelData"
    :modelData="element.value && element.value != null? element.value: []">
    <template v-slot:showType>
      <el-button
        style="width: 100%"
        @click="openModelDrawer"
        size="medium"
        type="primary"
        v-html="
          element.value && element.value != null && element.value.length !== 0
          ? '已选择': '请选择'"
      ></el-button>
    </template>
  </ChooseModel>
</template>

<script>
import ChooseModel from "@/components/bindModel/ButtonType.vue";
import formItemMinix from "@/components/formParser/NewParser/scripts/formItemMinix";

export default {
  name: "WidgetBimList",
  components: {ChooseModel},
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  mixins: [formItemMinix],
  methods: {
    openModelDrawer() {
      this.$nextTick(() => {
        this.$refs.chooseModel.show = true;
      })
    },
    setModelData(data) {
      this.element.value = data;
    },
  },
}
</script>

<style scoped>

</style>
