<!--
    @name: FlowOperate
    @description：FlowOperate
    @author: ZengWei
    @date: 2021-10-27 12:06
-->
<template>
  <div class="flow-operate">
    <div v-if="buttonStatus !== 0">
      <div class="type-label">流程审批意见</div>
      <el-form label-width="80px">
        <el-form-item label="审批意见">
          <el-input
            v-model="remarks"
            placeholder="请输入审批意见"
            :rows="3"
            type="textarea"
          />
        </el-form-item>
        <el-form-item label="审批附件">
          <fileUpload :files="files" @extra-files="extraFiles" />
        </el-form-item>
      </el-form>
    </div>
    <div v-if="buttonStatus === -1">
      <div class="type-label">选择退回类型</div>
      <el-form label-width="80px">
        <el-form-item label="审批意见">
          <el-radio-group v-model="back_type" size="small">
            <el-radio-button :label="1">退回发起人</el-radio-button>
            <el-radio-button :label="2">退回上一步</el-radio-button>
            <el-radio-button :label="3">指定步骤退回</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="back_type === 3" label="步骤选择">
          <el-select
            v-model="back_step"
            placeholder="请选择退回的步骤"
            style="width: 286px"
          >
            <el-option
              v-for="(item, index) in editData.doneStep"
              :key="index"
              :value="item.nodeId"
            >
              {{ item.name }}
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="buttonStatus > 0 && buttonStatus !== 4">
      <FlowPicker
        :data-id="dataId"
        :module="module"
        :turn="buttonStatus === 2 ? true : false"
        type="next"
        @checked-users="dealWithPicker"
        @selected-step="getSelectStep"
        @input-show="formItemShow"
      />
    </div>
    <div v-if="buttonStatus !== 0" style="text-align: right;margin-top: 15px">
      <el-button size="small" type="default" @click="buttonStatus = 0">
        取消
      </el-button>
      <el-button size="small" type="primary" @click="flowOperate">
        提交
      </el-button>
    </div>
    <div
      v-else-if="editData.btn && editData.btn.length !== 0"
      style="text-align: right"
    >
      <el-button
        v-for="(item, index) in editData.btn"
        :key="index"
        size="small"
        :type="item.status === -1 ? 'danger' : 'primary'"
        @click="actionEvent(item.status)"
      >
        {{ item.btn }}
      </el-button>
    </div>
  </div>
</template>

<script>
  import fileUpload from '@/components/fileUpload/fileUpload/Index.vue'
  import FlowPicker from './FlowPicker'
  import { flowEngine } from '@/api/workflow'

  export default {
    name: 'FlowOperate',
    components: { fileUpload,
			FlowPicker,
		},
    props: {
      editData: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    data(){
      return{
        buttonStatus: 0,
        files: [],
        back_type: 1,
        back_step: '',
        module:'',
        dataId:'',
        remarks: '',

        selectedUsers: [],
        checkPickerNum: 0,
        selectStep: '',
      }
    },
    methods:{
      formItemShow(list){
        this.$emit('input-show',list)
      },
      getSelectStep(nodeId) {
        this.selectStep = nodeId
      },
      dealWithPicker(selected, total) {
        this.selectedUsers = selected
        this.checkPickerNum = total
      },
      extraFiles(files){
        this.files = files
      },
      actionEvent(status){
        this.buttonStatus = status
      },
      flowOperate() {
        if (
          this.checkPickerNum !== 0 &&
          this.buttonStatus !== 4 &&
          this.checkPickerNum !== this.selectedUsers.target_users.length
        ) {
          this.$message.error('审批人未选择完整！')
          return false
        }
        let formData = {
          status: this.buttonStatus,
          module: this.module,
          remarks: this.remarks,
          appendix: this.files,
        }
        if (this.buttonStatus === -1) {
          formData.back_type = this.back_type
          if (this.back_type === 3) formData.back_step = this.back_step
        }
        if (
          this.selectedUsers.target_users &&
          this.selectedUsers.target_users.length > 0
        ) {
          formData.target_users = this.selectedUsers.target_users
        }
        if (
          this.selectedUsers.send_users &&
          this.selectedUsers.send_users.length > 0
        ) {
          formData.send_users = this.selectedUsers.send_users
        }
        if (this.selectStep) formData.select_step = this.selectStep

        flowEngine.flowOperate(this.dataId, formData).then((res) => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
          this.$emit('change-end')
        })
      },
    },
    created () {
      this.module = this.editData.module
      this.dataId = this.editData.id
    }
  }
</script>

<style lang="less" scoped>
  .flow-operate {
  }
</style>
