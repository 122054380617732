<!--
    @name: widget-radio
    @description：widget-radio
    @author: ZengWei
    @date: 2022-03-25 09:34
-->
<template>
  <el-radio-group
    v-model="element.value"
    :disabled="disabled || element.config.disabled"
    @change="triggerInteractive"
  >
    <template v-if="element.config.__config__.optionType === 'default'">
      <el-radio
        v-for="(val, key) in element.config.__slot__.options"
        :label="'' + val.value"
        :key="key"
      >{{ val.label }}
      </el-radio>
    </template>
    <template v-else>
      <el-radio-button
        v-for="(val, key) in element.config.__slot__.options"
        :label="'' + val.value"
        :key="key"
      >{{ val.label }}
      </el-radio-button>
    </template>
  </el-radio-group>
</template>

<script>
import { RadioGroup, Radio, RadioButton } from "element-ui";
import formItemMinix from "@/components/formParser/NewParser/scripts/formItemMinix";

export default {
  name: "WidgetRadio",
  components: {
    'el-radio-group': RadioGroup,
    'el-radio': Radio,
    'el-radio-button': RadioButton,
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  created() {
    this.triggerInteractive(this.element.value,'auto')
  },
  methods: {
    triggerInteractive(val,flag) {
      const interact = this.element.config.__config__.interact;
      const effect = interact.effect;
      const condition = interact.condition;
      if (effect === 'showhide') {
        let trueData = condition.filter(item => {
          if(typeof item.condV === 'string' && item.condV.indexOf(',')){
            let arr = item.condV.split(',');
            return arr.includes(val) || arr.includes(val.toString())
          }
          return item.condV == val
        })
        let compList = Array.from(condition, item => item.comp).flat();
        let compShowHide = {};
        for (let item of compList){
          compShowHide[item] = false;
        }
        if (trueData.length) {
          for (let eleItem of trueData){
            let compShow = eleItem.comp;
            for (let item of compShow){
              compShowHide[item] = true;
            }
          }
        }
        this.$emit('trigger-active', compShowHide,flag);
      }
    }
  },
}
</script>

<style scoped>

</style>
