<!--
    @name: widget-switch
    @description：widget-switch
    @author: ZengWei
    @date: 2022-03-25 09:36
-->
<template>
  <el-switch
    v-model="element.value"
    :disabled="disabled"
    @change="triggerInteractive"
  ></el-switch>
</template>

<script>
import {Switch} from "element-ui";
import formItemMinix from "@/components/formParser/NewParser/scripts/formItemMinix";

export default {
  name: "WidgetSwitch",
  components: {
    'el-switch': Switch
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  created() {
    this.triggerInteractive(this.element.value,'auto')
  },
  methods: {
  },
}
</script>

<style scoped>

</style>
