<!--
    @name: widget-drawingList
    @description：widget-drawingList
    @author: ZengWei
    @date: 2022-03-29 10:58
-->
<template>
  <ChooseDrawing
    ref="drawingListRef"
    @set-data="setData"
    :drawingData="element.value && element.value != null? element.value: []">
    <template v-slot:showType>
      <el-button
        style="width: 100%"
        @click="openModelDrawer('drawingListRef')"
        size="medium"
        type="primary"
        v-html="element.value && element.value != null && element.value.length != 0
          ? '已选择': '请选择'"
      ></el-button>
    </template>
  </ChooseDrawing>
</template>

<script>
import ChooseDrawing from "@/components/bindDrawing/ButtonType";
export default {
  name: "WidgetDrawingList",
  components: {ChooseDrawing},
  mixins: [],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  methods: {
    openModelDrawer(name) {
      this.$nextTick(()=>{
        this.$refs[name].show = true;
      })
    },
    setData(data){
      console.log(data,'数据')
      this.element.value = data;
    },
  },
}
</script>

<style scoped>

</style>
