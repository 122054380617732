<!--
    @name: widget-signature
    @description：widget-signature
    @author: ZengWei
    @date: 2022-04-11 18:29
-->
<template>
  <div>
    <div>
      <el-popover placement="right" width="100">
        <el-button slot="reference" @click="generateQrcode">
          <i class="iconfont iconfenbaoshenhe"></i>&nbsp;&nbsp;&nbsp;手写签名
        </el-button>
        <div style="text-align: center">
          <img v-if="qrCode" :src="qrCode" alt width="100" height="100" />
          <p>请打开手机扫一扫</p>
        </div>
      </el-popover>
      <el-button
        v-if="element.config.__config__.preSign"
        style="margin-left: 15px"
        type="text"
        @click="getUserSignature">
        使用预设签名
      </el-button>
    </div>
    <div>
      <el-image
        v-for="(item,index) in element.value"
        :key="index"
        style="width: 100px; height: 100px;margin: 15px"
        :src="item"
        fit="contain"
        :preview-src-list="[item]">
      </el-image>
    </div>
  </div>
</template>

<script>
import {Image,Popover} from "element-ui";
import { formRequest } from "@/api/home";
import { baseUrl } from '@/libs/util';

export default {
  name: "WidgetSignature",
  components: {
    'el-image': Image,
    'el-popover': Popover,
  },
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {
      qrCode: '',
      timer: null,
      qrToken: "",
    }
  },
  methods: {
    getUserSignature(){
      const url = '/api/v4/user/signature'
      formRequest('get', url ,{})
        .then(res=>{
          if (res.data.code === 200) {
            if (res.data.data !== null || res.data.data !== '') {
              this.signImg = baseUrl+'/'+res.data.data;
              this.element.value.push(this.signImg);
            } else {
              this.$message.error('您暂未预设签名，请到个人中心设置签名后使用')
            }
          }
        })
    },
    generateQrcode() {
      if(!this.qrCode){
        clearInterval(this.timer);
        const url = '/api/generate/signature'
        formRequest('post', url ,{})
          .then(res=>{
            if (res.data.code === 200) {
              this.qrCode = res.data.data.qrcode;
              this.qrToken = res.data.data.token;
              let that = this;
              this.timer = setInterval(() => {
                that.getAppSignature();
              }, 1000);
            }
          })
      }
    },
    getAppSignature() {
      const data = {qrToken: this.qrToken}
      const url= '/api/generate/signature'
      formRequest('get', url, data)
        .then((res) => {
          if (res.data.code === 200) {
            this.signImg = res.data.data.signature;
            if (this.signImg !== null) {
              this.element.value.push(this.signImg);
              clearInterval(this.timer);
            }
          }
        });
    },
  },
}
</script>

<style scoped>

</style>
