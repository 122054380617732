<!--
    @name: widget-number
    @description：widget-number
    @author: ZengWei
    @date: 2022-03-25 09:26
-->
<template>
  <el-input-number
    v-model="element.value"
    :disabled="disabled || element.config.disabled"
  ></el-input-number>
</template>

<script>
import formItemMinix from "@/components/formParser/NewParser/scripts/formItemMinix";
import {InputNumber} from "element-ui";

export default {
  name: "WidgetNumber",
  components: {
    'el-input-number': InputNumber
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped>

</style>
