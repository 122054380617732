<!--
    @name: FlowRecover
    @description：FlowRecover
    @author: ZengWei
    @date: 2021-10-28 11:23
-->
<template>
  <div class="flow-recover">
    <div class="type-label">
      流程审批记录
      <span style="float: right; cursor: pointer" @click="displayFlow">
        {{ flowShow ? '关闭' : '查看' }}流程图
      </span>
    </div>
    <div>
      <div v-if="flowShow" ref="topology" class="topology"></div>
    </div>
    <div class="flow-logs">
      <el-table border :data="flowLogs" stripe style="width: 100%">
        <el-table-column label="时间">
          <template #default="{ row }">
            {{ row.updated_at ? row.updated_at.substring(0, 16) : '-' }}
          </template>
        </el-table-column>
        <el-table-column label="处理人">
          <template #default="{ row }">
            {{ username(row) }}
          </template>
        </el-table-column>
        <el-table-column label="意见附件">
          <template #default="{ row }">
            <div v-if="row.images && row.images.length">
              <img
                v-for="(item, index) in row.images"
                :key="index + 'logImg'"
                class="logImg"
                :src="item"
                @click="handleView(row.images, index)"
              />
            </div>
            <div style="padding: 10px 0">
              <p v-for="(item, index) in row.files" :key="index + 'file'">
                {{ index + 1 }}、
                <a :download="item.filename" :href="item.filepath">
                  {{ item.filename }}
                </a>
              </p>
            </div>
            <div>{{ row.remarks }}</div>
          </template>
        </el-table-column>
        <el-table-column label="动作">
          <template #default="{ row }">
            <span
              :style="row.step_type == -1 ? 'color:#ec4031' : 'color:#0076FF'"
            >
              {{ row.name }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
  import { Topology } from '@topology/core'
  import { register as registerFlow } from '@topology/flow-diagram'
  import { register as registerActivity } from '@topology/activity-diagram'
	import { Table,TableColumn } from 'element-ui'
  let canvas
  const canvasOptions = {
    hideInput: true,
    grid: false,
    hideRotateCP: true,
    disableScale: false,
    rotateCursor: 'crosshair',
  }

  export default{
    name: 'FlowRecover',
    components: {'el-table': Table, 'el-table-column': TableColumn},
    props: {
      logs: {
        type: Array,
        default: () => {
          return []
        },
      },
      nextStep: {
        type: String,
        default: '',
      },
      canvasData: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    data(){
      return {
        flowShow: false,
        flowLogs:null,
      }
    },
    computed:{
      username(){
        return function (row) {
          let targetUsers = row.target_users
          let nameArr = Array.from(targetUsers, (item) => {
            return item.name || item.user_name
          })
          if (nameArr.length > 0) {
            return nameArr.join(',')
          }
          return row.user_name
        }
      }
    },
    methods:{
      handleView() {},
      dealWithCanvas(canvasData) {
        let allNode = canvasData.pens
        let repaint = [] //重绘数据
        let stepDone = [] //已处理的步骤
        for (const node of allNode) {
          if (node.data.color_status === 1) {
            stepDone.push(node.id)
          }
        }
        for (const node of allNode) {
          if (node.data.color_status === 1) {
            node.strokeStyle = '#40b67c'
            node.lineWidth = 2
          }
          if (node.name === 'polyline') {
            if (this.nextStep) {
              stepDone.push(this.nextStep)
              if (
                stepDone.indexOf(node.to.id) !== -1 &&
                stepDone.indexOf(node.from.id) !== -1
              ) {
                node.strokeStyle = '#40b67c'
                node.lineWidth = 2
              }
            } else {
              if (stepDone.indexOf(node.from.id) !== -1) {
                node.strokeStyle = '#40b67c'
                node.lineWidth = 2
              }
            }
          }
          if (node.data.target_user !== undefined) {
            let nameArr = Array.from(
              node.data.target_user,
              (item) => item.name
            )
            if (nameArr.length > 0) {
              let name = '审批人：' + nameArr.join(',')
              node.text = name
            }
          }
          repaint.push(node)
        }
        canvasData.pens = repaint
        return canvasData
      },
      displayFlow() {
        this.flowShow = !this.flowShow
        if (this.flowShow) {
          this.$nextTick(() => {
            canvas = new Topology(this.$refs.topology, canvasOptions)
            canvas.data.lineName = 'polyline'
            let recoverJson = this.dealWithCanvas(this.canvasData)
            canvas.open(recoverJson)
            canvas.centerView()
            canvas.fitView(5)
            canvas.lock(1)
            canvas.render()
          })
        } else {
          canvas.destroy()
        }
      },
    },
    created () {
      this.flowLogs = this.logs
      registerFlow()
      registerActivity()
    },
  }
</script>

<style lang="less" scoped>
  .flow-recover {
    .type-label {
      border-left: 3px solid #007fe0 !important;
      padding: 0 10px;
      line-height: 1;
      font-size: 14px;
      margin: 0 0 16px;
      font-weight: 600;
    }
    .topology {
      width: 100%;
      height: 400px;
    }
    .flow-logs {
      margin-bottom: 20px;
    }
  }
</style>
