
<!--
    @name: widget-user
    @description：widget-user
    @author: ZengWei
    @date: 2022-03-25 09:39
-->
<template>
  <select-user
    :key="key"
    :disabled="disabled"
    :data-info="element.config.__config__.userData || {}"
    :show-list="element.value"
    @on-select-change="onSelectChange"
  />
</template>

<script>
import selectUser from "@/components/formParser/selectUser/index";
import formItemMinix from "@/components/formParser/NewParser/scripts/formItemMinix";
import {formRequest} from "@/api/home";
import {uniqid} from "@/libs/util";

export default {
  name: "WidgetUser",
  components: {selectUser},
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {
      key:uniqid()
    }
  },
  created() {
    this.initUserData()
  },
  methods: {
    initUserData(){
      const url= '/api/mapi';
      const objectUuid = this.element.config.__config__.objectUuid
      const viewUuid = this.element.config.__config__.viewUuid
      const params = {
        __method_name__: 'dataList', object_uuid: objectUuid,
        view_uuid: viewUuid, transcode: 0,
        __now_archi_type: this.parser.nowArchiType,
      }
      formRequest('post', url, params).then(res => {
        let respData = res.data.data;
        if (res.data.data.data) {
          respData = res.data.data.data;
        }
        let userData = {list:respData, type:this.element.config.__config__.dataType}
        this.element.config.__config__.userData = userData
        if(this.displayData && Object.prototype.toString.call(this.displayData) === '[object Object]' && Object.keys(this.displayData).includes(this.element.field)){
          this.element.value = this.displayData[this.element.field];
        }
        this.key = uniqid()
      })
    },
    onSelectChange(userData) {
      let users = []
      for (let item of userData) {
        let user = {
          id: item.user_id,
          name: item.name,
          user_table: item.user_table,
          user_id: item.user_id,
          section_id: item.section_id,
        }
        users.push(user)
      }
      this.element.value = users;
    },
  },
}
</script>

<style scoped>

</style>
