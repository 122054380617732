export default {
	"fields": [{
		"__config__": {
			"label": "单行文本",
			"labelWidth": null,
			"showLabel": true,
			"changeTag": true,
			"tag": "el-input",
			"tagIcon": "input",
			"required": true,
			"layout": "colFormItem",
			"span": 24,
			"addShow": true,
			"openRule": false,
			"calcRules": [],
			"express": "",
			"serverValue": false,
			"serverFunc": "",
			"serverParams": [],
			"regList": [],
			"formId": "a7c7f344-1952-4892-925a-a4dc87413eb6",
			"renderKey": "a7c7f344-1952-4892-925a-a4dc87413eb6"
		},
		"__slot__": {
			"prepend": "",
			"append": ""
		},
		"placeholder": "请输入单行文本",
		"style": {
			"width": "100%"
		},
		"clearable": true,
		"prefix-icon": "",
		"suffix-icon": "",
		"maxlength": null,
		"show-word-limit": false,
		"readonly": false,
		"disabled": false,
		"__vModel__": "a7c7f344-1952-4892-925a-a4dc87413eb6"
	}, {
		"__config__": {
			"label": "单行文本",
			"labelWidth": null,
			"showLabel": true,
			"changeTag": true,
			"tag": "el-input",
			"tagIcon": "input",
			"required": true,
			"layout": "colFormItem",
			"span": 24,
			"addShow": true,
			"openRule": false,
			"calcRules": [],
			"express": "",
			"serverValue": false,
			"serverFunc": "",
			"serverParams": [],
			"regList": [],
			"formId": "4d4777ec-d20c-4a07-9a4c-e202483320f6",
			"renderKey": "4d4777ec-d20c-4a07-9a4c-e202483320f6"
		},
		"__slot__": {
			"prepend": "",
			"append": ""
		},
		"placeholder": "请输入单行文本",
		"style": {
			"width": "100%"
		},
		"clearable": true,
		"prefix-icon": "",
		"suffix-icon": "",
		"maxlength": null,
		"show-word-limit": false,
		"readonly": false,
		"disabled": false,
		"__vModel__": "4d4777ec-d20c-4a07-9a4c-e202483320f6"
	}, {
		"__config__": {
			"layout": "tabFormItem",
			"tag": "el-tab",
			"tagIcon": "tab",
			"label": "Tab布局",
			"layoutTree": true,
			"activeName": "first",
			"type": "",
			"tabPosition": "top",
			"addShow": true,
			"span": 24,
			"formId": "9630a195-8bce-4b33-a92b-3c3223b8b5bf",
			"renderKey": "9630a195-8bce-4b33-a92b-3c3223b8b5bf"
		},
		"__tabs__": [{
			"label": "选项卡1",
			"name": "first",
			"children": [{
				"__config__": {
					"label": "单行文本",
					"labelWidth": null,
					"showLabel": true,
					"changeTag": true,
					"tag": "el-input",
					"tagIcon": "input",
					"required": true,
					"layout": "colFormItem",
					"span": 24,
					"addShow": true,
					"openRule": false,
					"calcRules": [],
					"express": "",
					"serverValue": false,
					"serverFunc": "",
					"serverParams": [],
					"regList": [],
					"formId": "98747d52-2f8f-457d-ac79-ea0f52a562f3",
					"renderKey": "98747d52-2f8f-457d-ac79-ea0f52a562f3"
				},
				"__slot__": {
					"prepend": "",
					"append": ""
				},
				"placeholder": "请输入单行文本",
				"style": {
					"width": "100%"
				},
				"clearable": true,
				"prefix-icon": "",
				"suffix-icon": "",
				"maxlength": null,
				"show-word-limit": false,
				"readonly": false,
				"disabled": false,
				"__vModel__": "98747d52-2f8f-457d-ac79-ea0f52a562f3"
			}, {
				"__config__": {
					"label": "单行文本",
					"labelWidth": null,
					"showLabel": true,
					"changeTag": true,
					"tag": "el-input",
					"tagIcon": "input",
					"required": true,
					"layout": "colFormItem",
					"span": 24,
					"addShow": true,
					"openRule": false,
					"calcRules": [],
					"express": "",
					"serverValue": false,
					"serverFunc": "",
					"serverParams": [],
					"regList": [],
					"formId": "d4a19f2c-cbf4-4017-89eb-004d6e750d89",
					"renderKey": "d4a19f2c-cbf4-4017-89eb-004d6e750d89"
				},
				"__slot__": {
					"prepend": "",
					"append": ""
				},
				"placeholder": "请输入单行文本",
				"style": {
					"width": "100%"
				},
				"clearable": true,
				"prefix-icon": "",
				"suffix-icon": "",
				"maxlength": null,
				"show-word-limit": false,
				"readonly": false,
				"disabled": false,
				"__vModel__": "d4a19f2c-cbf4-4017-89eb-004d6e750d89"
			}]
		}, {
			"label": "选项卡2",
			"name": "second",
			"children": [{
				"__config__": {
					"label": "单行文本",
					"labelWidth": null,
					"showLabel": true,
					"changeTag": true,
					"tag": "el-input",
					"tagIcon": "input",
					"required": true,
					"layout": "colFormItem",
					"span": 24,
					"addShow": true,
					"openRule": false,
					"calcRules": [],
					"express": "",
					"serverValue": false,
					"serverFunc": "",
					"serverParams": [],
					"regList": [],
					"formId": "cbec71aa-2953-448d-becc-250dc7db6dc9",
					"renderKey": "cbec71aa-2953-448d-becc-250dc7db6dc9"
				},
				"__slot__": {
					"prepend": "",
					"append": ""
				},
				"placeholder": "请输入单行文本",
				"style": {
					"width": "100%"
				},
				"clearable": true,
				"prefix-icon": "",
				"suffix-icon": "",
				"maxlength": null,
				"show-word-limit": false,
				"readonly": false,
				"disabled": false,
				"__vModel__": "cbec71aa-2953-448d-becc-250dc7db6dc9"
			}, {
				"__config__": {
					"label": "单行文本",
					"labelWidth": null,
					"showLabel": true,
					"changeTag": true,
					"tag": "el-input",
					"tagIcon": "input",
					"required": true,
					"layout": "colFormItem",
					"span": 24,
					"addShow": true,
					"openRule": false,
					"calcRules": [],
					"express": "",
					"serverValue": false,
					"serverFunc": "",
					"serverParams": [],
					"regList": [],
					"formId": "ce380073-90e2-48bb-9511-27fa59152ea3",
					"renderKey": "ce380073-90e2-48bb-9511-27fa59152ea3"
				},
				"__slot__": {
					"prepend": "",
					"append": ""
				},
				"placeholder": "请输入单行文本",
				"style": {
					"width": "100%"
				},
				"clearable": true,
				"prefix-icon": "",
				"suffix-icon": "",
				"maxlength": null,
				"show-word-limit": false,
				"readonly": false,
				"disabled": false,
				"__vModel__": "ce380073-90e2-48bb-9511-27fa59152ea3"
			}]
		}, {
			"label": "选项卡3",
			"name": "third",
			"children": [{
				"__config__": {
					"label": "多行文本",
					"labelWidth": null,
					"showLabel": true,
					"tag": "el-input",
					"tagIcon": "textarea",
					"defaultValue": "",
					"required": true,
					"layout": "colFormItem",
					"span": 24,
					"regList": [],
					"changeTag": true,
					"addShow": true,
					"formId": "611a0ab2-7a24-407a-bd6d-af638e27703d",
					"renderKey": "611a0ab2-7a24-407a-bd6d-af638e27703d"
				},
				"type": "textarea",
				"placeholder": "请输入多行文本",
				"autosize": {
					"minRows": 4,
					"maxRows": 4
				},
				"style": {
					"width": "100%"
				},
				"maxlength": null,
				"show-word-limit": false,
				"readonly": false,
				"disabled": false,
				"__vModel__": "611a0ab2-7a24-407a-bd6d-af638e27703d"
			}]
		}]
	}],
	"formRef": "elForm",
	"formModel": "formData",
	"size": "medium",
	"labelPosition": "right",
	"labelWidth": 120,
	"formRules": "rules",
	"gutter": 15,
	"disabled": false,
	"span": 24,
	"formBtns": true,
	"baseLabel": true,
	"tableStyle": false,
	"fillMatch": {
		"manual": [{
			"label": "",
			"value": ""
		}],
		"select": [{
			"objectUuid": "",
			"viewUuid": "",
			"codeMatch": [{
				"label": "",
				"value": ""
			}]
		}]
	}
}







