<!--
 * @Author: Shiltin
 * @Date: 2021-10-20 17:52:57
 * @LastEditors: zx
 * @LastEditTime: 2022-09-08 16:25:29
 * @Description:
-->
<template>
  <div
    style="height: 100%"
    :style="!notForm ? 'max-width:' + maxShowWidth + 'px' : 'width:100%'"
  >
    <cascader
      :data-info="data3"
      :default-expand="defaultExpand"
      :disabled="disabled"
      :inpage="inpage"
      :is-check-type="isCheckType"
      :max-height="maxHeight"
      :position-type="positionType"
      :show-list="showList"
      :show-tree="showTree"
      :width="width"
      @on-select-change="onSelectChange"
    />
  </div>
</template>
<script>
import cascader from './components/cascader'
import {getValue} from '@/api/workflow'
import { getUserList } from './facilities'
export default{
  name: '',
  components: { cascader },
  props: {
    /* 流程组件内（inpage）页面盒子内使用示例
   <select-user
      :inpage="true"
      :not-form="true"
      :is-itself="true" //当前架构人员  不选下级
      :depart-id="210" //指定部门id
      @on-select-change="onSelectChange"
    />
   */
    dataInfo: {
      type: Array,
      default: () => []
    },
    /* 回显传入的选中的值[
          { user_id: 1, section_id: 1 },
          { user_id: 14, section_id: 1 },
        ], */
    showList: {
      type: Array,
      default: () => []
    },
    /* 指定公司id */
    companyId: {
      type: Number,
      default: 0
    },
    /* 指定部门id */
    departId: {
      type: Array,
      default: () => []
    },
    /* 指定项目id */
    projectId: {
      type: Number,
      default: 0
    },
    /* 只请求架构本身的人员  不包括下级单位 */
    isItself: {
      type: Boolean,
      default: false
    },
    /* 回显传入的选中的值 */
    inpage: {
      type: Boolean,
      default: false
    },
    // cascaderCheck 级联单选
    // cascaderCheckbox 级联多选
    isCheckType: {
      type: String,
      default: 'cascaderCheckbox'
    },
    /* 点击选择窗口位置 */
    positionType: {
      type: String,
      default: 'bottom-start'
    },
    /* 弹出框宽度 */
    width: {
      type: Number,
      default: 450
    },
    /* 弹出框最大高度 */
    maxHeight: {
      type: Number,
      default: 300
    },
    /* 弹出框宽度 */
    notForm: {
      type: Boolean,
      default: false
    },
    /* 最大的显示人的长度(form表单默认100%会撑开，不能超出隐藏，form表单下使用需要设置宽度数值) */
    maxShowWidth: {
      type: Number,
      default: 428
    },
    /* 默认展开 */
    defaultExpand: {
      type: Boolean,
      default: true
    },
    pickerType: {
      type: String,
      default: 'target'
    },
    pickerNode: {
      type: String,
      default: ''
    },
    /* 禁用选择 */
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      userInfo:{},
      archiInfo:{},
      data3: null,
      showTree: true
    }
  },
  methods: {
    onSelectChange(arr){
      const pickerNode = this.pickerNode
      const pickerType = this.pickerType
      const params = { pickerNode, pickerType }
      this.$emit('on-select-change', arr, params)
    },
    companySectionEdit(data){
      let arr = data
      if (arr.length) {
        arr.forEach((item) => {
          item.children = item.users
        })
        arr = arr.filter((item) => item.children.length > 0)
        let key = 1
        if (arr.length) {
          arr.forEach((element) => {
            element.children.forEach((itemz) => {
              itemz.nodeKey = key
              itemz.departmentName = element.name
              key += 1
            })
          })
          this.data3 = JSON.parse(JSON.stringify(arr))
          this.showTree = false
        }
      }
    },
    projectSectionEdit(data){
      const arr = []
      let key = 1
      data.forEach((element) => {
        const project_name = element.name
        const project_id = element.id
        const sections = []
        element.sections.forEach((item) => {
          if (item.users && item.users.length) {
            item.children = item.users
            item.children.forEach((user) => {
              user.nodeKey = key
              key += 1
              user.project_name = project_name
              user.project_id = project_id
            })
            if (item.children && item.children.length) {
              sections.push(item)
            }
          }
        })
        element.children = sections
        if (element.sections && element.sections.length) {
          arr.push(element)
        }
      })
      this.data3 = JSON.parse(JSON.stringify(arr))
      this.showTree = false
    }
  },
  created () {
    // this.userInfo = this.$GetUserInfo()
    // this.archiInfo = this.$GetTargetArchi()
  },
  mounted () {
    const isArchiCompany = this.archiInfo.archiType === 'company' // 当前登录是否是公司端
      const archiId = this.archiInfo.archiId
        ? this.archiInfo.archiId
        : this.archiInfo.id.split('-')[1] // 当前登录的架构id
      if (this.dataInfo && this.dataInfo.length) {
        // const list = JSON.parse(JSON.stringify(this.dataInfo))
        // userEdit(list);
      } else if (this.projectId && !this.isItself) {
        /* 指定项目id 获取本级下级所有信息 */
        const obj = {
          object_uuid: 'object616fe6fe8119f',
          view_uuid: 'view6177ce7668c82',
          __method_name__: 'dataList',
          transcode: 0,

          project_id: this.projectId
        }
        getValue(obj).then((res) => {
          if (res.status === 200) {
            const list = JSON.parse(JSON.stringify(res.data.data))
            const arr = getUserList(list, 'project', 'section')
            let key = 1
            const nodeKey = ($list) => {
              $list.forEach((element) => {
                if (element.section_id && element.user_id) {
                  element.nodeKey = key
                  key += 1
                } else if (element.children && element.children.length) {
                  nodeKey(element.children)
                }
              })
            }
            nodeKey(arr)
            this.data3 = JSON.parse(JSON.stringify(arr))
            this.showTree = false
          }
        })
      } else if (this.projectId && this.isItself) {
        /* 指定项目  并且项目当前架构 */
        const obj = {
          object_uuid: 'object617117a3ec2ce',
          view_uuid: 'view61793d2a8e4f5',
          __method_name__: 'dataList',
          transcode: 0,
          project_id: this.projectId,
          type: this.isItself ? 1 : 0
        }
        getValue(obj).then((res) => {
          if (res.status === 200) {
            const list = JSON.parse(JSON.stringify(res.data.data))
            this.projectSectionEdit(list)
          }
        })
      } else if (this.companyId && !this.isItself) {
        /* 指定公司  并且公司及下级所有信息 */
        const obj = {
          object_uuid: 'object616fd8131c8f1',
          view_uuid: 'view617911f24b73d',
          __method_name__: 'dataList',
          transcode: 0,
          company_id: this.companyId
        }
        getValue(obj).then((res) => {
          if (res.status === 200) {
            const list = JSON.parse(JSON.stringify(res.data.data))
            const arr = getUserList(list, 'company', 'section')
            let key = 1
            const nodeKey = ($list) => {
              $list.forEach((element) => {
                if (element.section_id && element.user_id) {
                  element.nodeKey = key
                  key += 1
                } else if (element.children && element.children.length) {
                  nodeKey(element.children)
                }
              })
            }
            nodeKey(arr)
            this.data3 = JSON.parse(JSON.stringify(arr))
            this.showTree = false
          }
        })
      } else if (this.companyId && this.isItself) {
        /* 指定公司 并且公司当前架构 */
        const obj = {
          object_uuid: 'object6170c2310014e',
          view_uuid: 'view61762340488fb',
          __method_name__: 'dataList',
          transcode: 0,
          company_id: this.companyId,
          type: this.isItself ? 1 : ''
        }
        getValue(obj).then((res) => {
          if (res.status === 200) {
            const list = JSON.parse(JSON.stringify(res.data.data))
            this.companySectionEdit(list)
          }
        })
      } else if (this.departId && this.departId.length > 0) {
        /* 指定部门id */
        const obj = {
          object_uuid: 'object6170c2310014e',
          view_uuid: 'view617cff30ab17e',
          __method_name__: 'dataList',
          transcode: 0,
          ids: this.departId
        }
        getValue(obj).then((res) => {
          if (res.status === 200) {
            const list = JSON.parse(JSON.stringify(res.data.data))
            /* 指定部门id用公司当前架构的数据处理函数 */
            this.companySectionEdit(list)
          }
        })
      } else if (isArchiCompany) {
        /* 未指定部门和项目公司  默认登陆用户当前架构 */
        const obj = {
          object_uuid: 'object616fd8131c8f1',
          view_uuid: 'view617911f24b73d',
          __method_name__: 'dataList',
          transcode: 0,
          company_id: archiId
        }
        if (this.isItself) {
          obj.object_uuid = 'object6170c2310014e'
          obj.view_uuid = 'view61762340488fb'
          obj.type = 1
        }
        getValue(obj).then((res) => {
          if (res.status === 200) {
            const list = JSON.parse(JSON.stringify(res.data.data))
            /* 公司全部人员 */
            if (!this.isItself) {
              const arr = getUserList(list, 'company', 'section')
              let key = 1
              const nodeKey = ($list) => {
                $list.forEach((element) => {
                  if (element.section_id && element.user_id) {
                    element.nodeKey = key
                    key += 1
                  } else if (element.children && element.children.length) {
                    nodeKey(element.children)
                  }
                })
              }
              nodeKey(arr)
              this.data3 = JSON.parse(JSON.stringify(arr))
              this.showTree = false
            } else {
              // 公司当前架构人员
              this.companySectionEdit(list)
            }
          }
        })
      } else {
        /* 未指定部门和项目公司  默认登陆用户当前架构 */
        const obj = {
          object_uuid: 'object616fe6fe8119f',
          view_uuid: 'view6177ce7668c82',
          __method_name__: 'dataList',
          transcode: 0,
          project_id: archiId
        }
        if (this.isItself) {
          obj.object_uuid = 'object617117a3ec2ce'
          obj.view_uuid = 'view61793d2a8e4f5'
          obj.type = 1
        }
        getValue(obj).then((res) => {
          if (res.status === 200) {
            const list = JSON.parse(JSON.stringify(res.data.data))
            if (!this.isItself) {
              const arr = getUserList(list, 'project', 'section')
              let key = 1
              const nodeKey = ($list) => {
                $list.forEach((element) => {
                  if (element.section_id && element.user_id) {
                    element.nodeKey = key
                    key += 1
                  } else if (element.children && element.children.length) {
                    nodeKey(element.children)
                  }
                })
              }
              nodeKey(arr)
              this.data3 = JSON.parse(JSON.stringify(arr))
              this.showTree = false
            } else {
              this.projectSectionEdit(list)
            }
          }
        })
      }
  }
}
</script>
<style lang="less" scoped></style>
