<!--
    @name: widget-gisList
    @description：widget-gisList
    @author: ZengWei
    @date: 2022-03-25 09:39
-->
<template>
  <div></div>
</template>

<script>
import formItemMinix from "@/components/formParser/NewParser/scripts/formItemMinix";

export default {
  name: "WidgetGisList",
  components: {},
  mixins: [formItemMinix],
  props: {},
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped>

</style>
