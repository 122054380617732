<!--
    @name: widget-rich-text
    @description：widget-rich-text
    @author: ZengWei
    @date: 2022-03-25 09:27
-->
<template>
  <div>
    <Editor
      :disabled="disabled || element.config.disabled"
      :init="setting"
      v-model="element.value">
    </Editor>
  </div>
</template>

<script>
/* eslint-disable */
import Editor from '@tinymce/tinymce-vue'
import tinymce from 'tinymce/tinymce'

import 'tinymce/themes/silver' // 编辑器主题，不引入则报错
import 'tinymce/icons/default' // 引入编辑器图标icon，不引入则不显示对应图标
// import 'tinymce/skins/ui/oxide/skin.min.css'
// import 'tinymce/skins/ui/oxide/content.inline.min.css'
// import 'tinymce/skins/ui/oxide/skin.shadowdom.min.css'
import '@/assets/zh_CN'
// 引入编辑器插件（基本免费插件都在这儿了）
import 'tinymce/plugins/advlist' // 高级列表
import 'tinymce/plugins/anchor' // 锚点
import 'tinymce/plugins/autolink' // 自动链接
import 'tinymce/plugins/autoresize' // 编辑器高度自适应,注：plugins里引入此插件时，Init里设置的height将失效
import 'tinymce/plugins/autosave' // 自动存稿
import 'tinymce/plugins/charmap' // 特殊字符
import 'tinymce/plugins/code' // 编辑源码
import 'tinymce/plugins/codesample' // 代码示例
import 'tinymce/plugins/directionality' // 文字方向
import 'tinymce/plugins/emoticons' // 表情
import 'tinymce/plugins/fullpage' // 文档属性
import 'tinymce/plugins/fullscreen' // 全屏
import 'tinymce/plugins/help' // 帮助
import 'tinymce/plugins/hr' // 水平分割线
import 'tinymce/plugins/image' // 插入编辑图片
import 'tinymce/plugins/importcss' // 引入css
import 'tinymce/plugins/insertdatetime' // 插入日期时间
import 'tinymce/plugins/link' // 超链接
import 'tinymce/plugins/lists' // 列表插件
import 'tinymce/plugins/media' // 插入编辑媒体
import 'tinymce/plugins/nonbreaking' // 插入不间断空格
import 'tinymce/plugins/pagebreak' // 插入分页符
import 'tinymce/plugins/paste' // 粘贴插件
import 'tinymce/plugins/preview'// 预览
import 'tinymce/plugins/print'// 打印
import 'tinymce/plugins/quickbars' // 快速工具栏
import 'tinymce/plugins/save' // 保存
import 'tinymce/plugins/searchreplace' // 查找替换
import 'tinymce/plugins/tabfocus' // 切入切出，按tab键切出编辑器，切入页面其他输入框中
import 'tinymce/plugins/table' // 表格
import 'tinymce/plugins/template' // 内容模板
import 'tinymce/plugins/textcolor' // 文字颜色
import 'tinymce/plugins/textpattern' // 快速排版
import 'tinymce/plugins/toc' // 目录生成器
import 'tinymce/plugins/visualblocks' // 显示元素范围
import 'tinymce/plugins/visualchars' // 显示不可见字符
import 'tinymce/plugins/wordcount' // 字数统计

import {formRequest} from "@/api/home";

export default {
  name: "WidgetRichText",
  components: { Editor },
  mixins: [],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {
      setting: {
        language: 'zh_CN',
        menubar: false, // 禁用菜单栏
        plugins: 'print preview searchreplace fullscreen autolink image link media code' +
          ' codesample table charmap hr pagebreak nonbreaking advlist lists textpattern help',
        toolbar: `undo redo restoredraft
        |formatselect
        |bold italic
        |alignleft aligncenter alignright alignjustify
        |bullist numlist
        |removeformat
        |fontselect fontsizeselect forecolor backcolor lineheight
        |underline strikethrough link autolink blockquote superscript subscript outdent indent
        |table image media hr codesample insertdatetime visualblocks
        |code preview searchreplace fullscreen`,
        toolbar_mode: 'sliding',
        placeholder: '请输入正文',
        branding: false,
        autosave_ask_before_unload: false,
        font_formats: '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;' +
          '苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;' +
          'Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;' +
          'Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;' +
          'Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;' +
          'Terminal=terminal,monaco;Times New Roman=times new roman,times;Verdana=verdana,geneva;Webdings=webdings;' +
          'Wingdings=wingdings,zapf dingbats;' +
          '知乎配置=BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, ' +
          'Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;' +
          '小米配置=Helvetica Neue,Helvetica,Arial,Microsoft Yahei,Hiragino Sans GB,Heiti SC,WenQuanYi Micro Hei,sans-serif',
        fontsize_formats: '12px 14px 16px 18px 24px 36px 48px 56px 72px',
        formats: {
          h1: { block: 'h1', classes: 'aiming-point' },
          h2: { block: 'h2', classes: 'aiming-point' },
          h3: { block: 'h3', classes: 'aiming-point' },
          h4: { block: 'h4', classes: 'aiming-point' },
          h5: { block: 'h5', classes: 'aiming-point' }
        },
        // 图片上传
        images_upload_handler: function (blobInfo, success, failure) {
          if (blobInfo.blob().size / 1024 / 1024 > 20) {
            failure('上传失败，图片大小请控制在 20M 以内')
          } else {
            const data = new FormData()
            data.append('file', blobInfo.blob())
            const headers = {
              'Content-Type': 'multipart/form-data',
              metadatatoken: process.env.VUE_APP_METATOKEN
            }
            formRequest('post','/api/mapi?__method_name__=file', data, headers).then(res => {
              if (res.code === 200) {
                success(res.data.url.replace('_thumb', ''))
              } else {
                failure('上传失败')
              }
            })
          }
        },
        height: 350
      },
    }
  },
  mounted() {
    tinymce.init({})
  },
  methods: {},
}
</script>

<style scoped>

</style>
