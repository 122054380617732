<!--
    @name: widget-cascader
    @description：widget-cascader
    @author: ZengWei
    @date: 2022-03-25 09:36
-->
<template>
  <el-cascader
    v-model="element.value"
    :disabled="disabled || element.config.disabled"
    :options="element.config.options"
    :placeholder="element.config.placeholder"
    :clearable="element.config.clearable"
    :filterable="element.config.filterable"
  >
  </el-cascader>
</template>

<script>
import formItemMinix from "@/components/formParser/NewParser/scripts/formItemMinix";
import {Cascader} from "element-ui";

export default {
  name: "WidgetCascader",
  components: {
    'el-cascader': Cascader
  },
  props: ['element', 'disabled', 'displayData'],
  mixins: [formItemMinix],
  data() {
    return {}
  },
  created() {
    this.loadingDynamicData(this.element);
  },
  methods: {},
}
</script>

<style scoped>

</style>
