<!--
    @name: widget-modelView
    @description：widget-modelView
    @author: ZengWei
    @date: 2022-03-25 09:43
-->
<template>
  <ChooseModel
    ref="chooseView"
    chooseType="view"
    @set-data="setModelData"
    :modelData="element.value && element.value != null? element.value: []">
    <template v-slot:showType>
      <el-button
        style="width: 100%"
        @click="openModelDrawer('chooseView')"
        size="medium"
        type="primary"
        v-html="
          element.value && element.value != null && element.value.length != 0
          ? '已选择': '请选择'"
      ></el-button>
    </template>
  </ChooseModel>
</template>

<script>
import ChooseModel from "@/components/bindModel/ButtonType.vue";
import formItemMinix from "@/components/formParser/NewParser/scripts/formItemMinix";

export default {
  name: "WidgetModelView",
  components: {ChooseModel},
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  methods: {
    openModelDrawer(name) {
      this.$nextTick(() => {
        this.$refs[name].show = true;
      })
    },
    setModelData(data) {
      this.element.value = data;
    },
  },
}
</script>

<style scoped>

</style>
