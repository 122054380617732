<!--
    @name: index
    @description：index
    @author: ZengWei
    @date: 2021-11-18 10:53
-->
<template>
	<div style="padding: 15px;height: 100%;overflow-y: auto">
    <parser :formData="formDesignData" :hideBtn="false"></parser>
	</div>
</template>

<script>
/* eslint-disable */
	import parser from "@/components/formParser/NewParser/PcParser";
	import formData from "./testData"
	import skeleton from "@/components/formParser/skeleton";
  import InputItem from "@/components/formParser/NewParser/InputItem";

	export default {
		name: "index",
		components: {parser,skeleton,InputItem},
		props: {},
		data() {
			return {
				formDesignData: formData,
			}
		},
		created() {
		},
		mounted() {

		},
		methods: {

		},
	}
</script>

<style scoped>

</style>
