/* eslint-disable */
import formatElement from "@/components/formParser/NewParser/scripts/formatElement";

export const formValidate = (formJson, recoverData, fillMatch, fillData, data = []) => {
	for (let ele of formJson) {
		const itemData = formatElement(ele, fillMatch, fillData, recoverData); //格式化后的item数据

		const children = ele.__config__?.children || [];
		const fillDataCopy = ele.__config__?.fillData || [];

		if (ele.__config__.tagIcon === 'tab') {
			itemData.value = [];
			for (let tab of ele.__tabs__) {
				let childrenData = formValidate(tab.children, recoverData, fillMatch, fillData, []);
				itemData.value.push(childrenData);
			}
		} else if (ele.__config__.tagIcon === 'form') {
			let childrenData = formValidate(ele.__config__.children, recoverData, fillMatch, fillData, []);
			let relateIndex = childrenData.findIndex(item => item.type === 'database')
			if (relateIndex !== -1) {
				const otherComp = childrenData.filter(item => item.type !== 'database')
				const relateComp = childrenData[relateIndex]
				relateComp.config.__slot__.options = relateComp.config.__slot__.options.filter(item => !item.element)
				for (let item of otherComp) {
					let attachItem = {
						label: item.config.__config__.label,
						value: item.config.__vModel__,
						width: item.config.__config__.width || 150,
						element: item
					}
					relateComp.config.__slot__.options.push(attachItem)
				}
				itemData.config.__config__.display = 'table-relate'
				itemData.config.__config__.relateConfig = relateComp.config;
			} else if (fillDataCopy && fillDataCopy.length > 0) {
				const fillItem = []; //预设填充数据
				for (const item of ele.__config__.fillData) {
					const formId = Object.keys(item)
					const cycleData = JSON.parse(JSON.stringify(childrenData));
					cycleData.forEach((ite) => {
						if (formId.includes(ite.config.__config__.formId)) {
							ite.value = item[ite.config.__config__.formId]
							//填充数据禁用输入
							if (ele.__config__.fillType && ele.__config__.fillType === 'text') {
								ite.config.disabled = true
							}
						}
					})
					fillItem.push(cycleData)
				}
				itemData.value = fillItem;
			}
		} else if (children && children.length > 0) {
			const childrenData = formValidate(ele.__config__.children, fillMatch, fillData, []);
			itemData.value = childrenData;
		}

		data.push(itemData);
	}
	return data;
}

/**
 * 递归采集表单输入数据
 * @param formInput
 * @param recoverData
 * @param submitData
 * @return {{}}
 */
export const getSubmitData = (formInput,recoverData= {},submitData = {}) => {
	for (let eleItem of formInput) {
		if(eleItem.type === 'row'){
			if(eleItem.value instanceof Array){
				getSubmitData(eleItem.value,recoverData,submitData)
			}
		} else if(eleItem.type === 'tab'){
			if(eleItem.value instanceof Array){
				for (let tab of eleItem.value) {
					getSubmitData(tab,recoverData,submitData)
				}
			}
		} else if (eleItem.type === 'table'){
			const rows = eleItem.config.widget.rows;
			for (let row of rows) {
				for (let col of row.cols) {
					getSubmitData(col.widgetList,recoverData,submitData)
				}
			}
		} else if (eleItem.type === 'form'){
			let fieldData = [];
			const equalField = eleItem.config.__config__.addShow;
			if(equalField === undefined || equalField){ // 存在交互时，并且字段相同，数据相互覆盖bug
				let childExist = null // 修改数据时，关联数据修改，还原原始数据
				if(Object.keys(recoverData).includes(eleItem.field)){
					childExist = recoverData[eleItem.field]
				}
				for (let eleInd in eleItem.value) {
					let itemField = {};
					const eleData = eleItem.value[eleInd]
					if(childExist && childExist[eleInd]){
						itemField = childExist[eleInd]
					}
					for (let eleItem of eleData) {
						itemField[eleItem.field] = eleItem.value;
					}
					fieldData.push(itemField);
				}
				submitData[eleItem.field] = fieldData;
			}
		} else {
			if(eleItem?.field){
				submitData[eleItem.field] = eleItem.value
			}
		}
	}

	return submitData
}
