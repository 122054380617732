<!--
    @name: widget-upload
    @description：widget-upload
    @author: ZengWei
    @date: 2022-03-25 09:38
-->
<template>
  <uploader
    :disabled="disabled"
    :files="element.value"
    :fileTypeList="fileTypeList"
    @extra-files="onSuccess"
  >
  </uploader>
</template>

<script>
import uploader from "@/components/fileUpload/fileUpload/Index";
import formItemMinix from "@/components/formParser/NewParser/scripts/formItemMinix";

export default {
  name: "WidgetUpload",
  components: {uploader},
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  computed:{
    fileTypeList(){
      const type = this.element.config.accept.split(',')
      const typeArr = type.filter(item => {return item && item.trim()})
      return typeArr
    },
  },
  methods: {
    onSuccess(files) {
      this.element.value = files;
    },
  },
}
</script>

<style scoped>

</style>
