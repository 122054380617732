<!--
    @name: widget-time
    @description：widget-time
    @author: ZengWei
    @date: 2022-03-25 09:36
-->
<template>
  <el-time-picker
    v-model="element.value"
    :disabled="disabled || element.config.disabled"
    :type="element.config.type"
    :format="element.config.format"
    :value-format="element.config['value-format']"
    :placeholder="element.config.placeholder"
    style="width: 100%"
  >
  </el-time-picker>
</template>

<script>
import {TimePicker} from "element-ui"
import formItemMinix from "@/components/formParser/NewParser/scripts/formItemMinix";

export default {
  name: "WidgetTime",
  components: {
    'el-time-picker': TimePicker
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped>

</style>
