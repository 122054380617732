<!--
    @name: widget-longlat
    @description：widget-longlat
    @author: ZengWei
    @date: 2022-04-24 16:21
-->
<template>
  <div>
    <BindLatLng ref="chooseLngLat" @set-data="setData" :modelData="element.value && element.value != null? element.value: null">
      <template v-slot:showType>
        <el-button
          style="width: 100%"
          @click.stop="openModelDrawer('chooseLngLat')"
          size="medium"
          type="primary"
          v-html="
          element.value && element.value != null 
          ? '已选择': '请选择'"
        ></el-button>
      </template>
    </BindLatLng>
  </div>
</template>

<script>
import BindLatLng from "@/components/bindLatLng/ButtonType.vue";

export default {
  name: "WidgetLonglat",
  components: {
    BindLatLng
  },
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  methods: {
    setData(lngLat){
      this.element.value = lngLat
    },
    openModelDrawer(name) {
      this.$nextTick(() => {
        this.$refs[name].show = true;
				console.log(this.$refs[name],'this.$refs[name]')
      })
    },
  },
}
</script>

<style scoped>

</style>
