<!--
    @name: PcParser
    @description：PcParser
    @author: ZengWei
    @date: 2022-03-29 09:30
-->
<template>
  <div>
    <div v-if="skeleton" class="skeleton">
      <skeleton></skeleton>
    </div>
    <div v-else>
      <el-form
        ref="formParser"
        size="medium"
        :class="{'form-parser':tableStyle,'normal-parser': !tableStyle}"
        :model="formValidate"
        :show-message="!tableStyle"
        :label-width="formData.labelWidth+'px'"
        :label-position="formData.labelPosition"
      >
        <div v-if="formLabel" class="type-label">{{ formLabel }}</div>
        <el-row :gutter="gutter">
          <el-col
            v-for="(item, index) in formValidate.items"
            :span="item.config.__config__.span"
            :key="index"
          >
            <!--兼容隐藏控件的交互事件-->
            <template v-if="['radio','switch','select'].indexOf(item.type) >-1 && !showHideField(item.config.__config__)">
              {{ initTrigger(item) }}
            </template>

            <template
              v-if="['form','row','table','tab','steps','text'].includes(item.type)
                && showHideField(item.config.__config__)">
              <InputItem
                ref="childParser"
                :element="item"
                :disabled="canEditField(item.field)"
                :editFields="editFields"
                :formData="formConf"
                :displayData="displayData"
                @trigger-active="triggerActive(arguments)"
                @auto-fill-relate="selectAutoFillRelate"
              >
              </InputItem>
            </template>

            <template v-else-if="showHideField(item.config.__config__)">
              <el-form-item
                class="no-padding"
                :prop="'items.' + index + '.value'"
                :label="item.config.__config__.label"
                :rules="item.validate"
                :label-width="
                item.config.__config__.labelWidth
                  ? item.config.__config__.labelWidth + 'px'
                  : 120 + 'px'"
              >
                <InputItem
                  ref="childParser"
                  :element="item"
                  :disabled="canEditField(item.config.__config__.formId)"
                  :editFields="editFields"
                  :formData="formConf"
                  :displayData="displayData"
                  @trigger-active="triggerActive(arguments)"
                  @auto-fill-relate="selectAutoFillRelate"
                >
                </InputItem>
              </el-form-item>
            </template>
          </el-col>
        </el-row>

        <div class="parser-tp">
          <slot name="other"></slot>
        </div>

        <div class="parser-tp">
          <template v-if="needFlow">
            <template v-if="formMode === 'add'">
              <!--添加界面流程-->
              <FlowPicker
                :module="moduleUuid"
                type="self"
                @checked-users="dealWithPicker"
                @selected-step="getSelectStep"
              />
            </template>
            <template v-else-if="formMode === 'detail'">
              <!--详情界面流程-->
              <FlowRecover
                v-if="Object.keys(displayData).length"
                :canvas-data="displayData.recover"
                :logs="displayData.logs"
                :next-step="displayData.nextStep"
              />
              <FlowOperate
                v-if="Object.keys(displayData).length && displayData.btn && displayData.btn.length"
                :edit-data="displayData"
                :flow-edit="editFieldsValue"
                @change-end="onRefresh"
              />
            </template>
          </template>
        </div>

        <div class="parser-tp">
          <slot name="button"></slot>
        </div>

        <div v-if="!hideBtn" style="text-align: right;margin-top: 15px;">
          <el-button type="default" @click="handleReset" style="margin-right: 10px;">取消</el-button>
          <el-button type="primary" @click="handleSubmit">提交</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import parserMethods from "@/components/formParser/scripts/parserMethods";
import skeleton from "@/components/formParser/skeleton";
import { calcIF, calcMULTIPLY, calcSUM } from "@/libs/calcRuleEngine";
import FlowPicker from "@/components/formParser/flowEngine/components/FlowPicker";
import FlowOperate from "@/components/formParser/flowEngine/components/FlowOperate";
import FlowRecover from "@/components/formParser/flowEngine/components/FlowRecover";
import { getNowTime } from "@/libs/util";

export default {
  name: "PcParser",
  components: { skeleton, FlowPicker, FlowRecover, FlowOperate },
  props: {
    //可编辑字段
    editFields: {
      type: [Object,Array],
      default: ()=>{
        return {}
      },
    },
    // 显示验证错误信息
    showMessage: {
      type: Boolean,
      default: false,
    },
    // 隐藏默认按钮
    hideBtn: {
      type: Boolean,
      default: true,
    },
    // 表单禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    // 表单设计数据
    formData: {
      type: Object,
    },
    // 表单回显数据
    displayData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 架构限制
    nowArchiType: {
      type: [String,Number],
      default: '',
    },
    // 表单模式-新增/编辑
    formMode: {
      type: String,
      default: 'add',
    },
    // 表单填充数据
    fillData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 是否开启流程
    needFlow: {
      type: Boolean,
      default: false,
    },
    // 表单唯一标识--用于流程
    moduleUuid: {
      type: String,
      default: '',
    },
  },
  provide(){
    return {
      parser: this,
    }
  },
  data() {
    return {
      currentInfo:{},
      skeleton: true,
      formConf: {
        tableStyle: this.formData?.tableStyle || false,
        labelWidth: this.formData?.labelWidth,
        labelPosition: this.formData?.labelPosition,
      },
      formValidate: {
        items: [],
      },
      tdElement: null,
      validator: false,
      formSubmitData: null,
      relateData: [],
      relateItemInd: '',
      relateVisible: false,
      relateConfig: null,
      relateObjectUUid: null,
      relateViewUUid: null,
      relateFilterRule:[],
      formLabel: '',
      tableStyle: false,
      // 流程数据
      selectedUsers: [],
      checkPickerNum: 0,
      selectStep: '',
      flowEngineId:0,
      // 外部表单
      outerFormIndex:null,
      outerFormData:null,
      outerNeedFlow: false,
      outerFormUuid: '',
      outerObjectUuid: '',
      outerFormMode: 'add',
      outerFormDisplay: {},
      outerFormType: 1,
      outerFormShowType: 1,
      outerFormVisible:false,
      outerFormTitle: '外部表单',

      editFieldsValue:{} //流程中编辑表单值
    };
  },
  computed: {
    gutter(){
      if(this.tableStyle){
        return 0
      } else {
        return this.formData.gutter || 15
      }
    },
    initTrigger(){
      return function (element) {
        let value = element.value;
        if(element.type === 'steps') {
          value = element.config.__config__.activeName;
        }
        this.triggerEventCtrl(element,value)
      };
    },
    showLabel() {
      return function (config) {
        if (config.showLabel === undefined || config.showLabel === false)
          return "";
        return config.label;
      };
    },
    canEditField() {
      return function (formId) {
        const editFormId = Object.keys(this.editFields);
        if (editFormId.length > 0 && editFormId.includes(formId)){
          return this.editFields[formId];
        }
        return this.disabled;
      };
    },
    showHideField() {
      return function (config) {
        const formId = config.formId;
        const editFormId = Object.keys(this.editFields);
        if(editFormId.includes(formId)) return true;
        return config.addShow;
      };
    }
  },
  watch: {
    formValidate: {
      handler() {
        this.carryCalculate();
        if(Object.keys(this.editFields).length){
          this.editFieldsInput(); // 改变即获取流程中编辑字段
        }
        // this.setDynamicData();
      },
      deep: true,
    }
  },
  created() {
    window.calcField = this.getFieldValue.bind(this)
    window.calcIF = calcIF
    window.calcMULTIPLY = calcMULTIPLY
    window.calcSUM = calcSUM

    //附加当前填充数据 -- 当前用户，当前时间，当前项目
    const userInfo = {}
    const archiInfo = {}
    const nowData = getNowTime(1);
    const nowDataTime = getNowTime(2);
    const nowTime = getNowTime(3);
    this.currentInfo = {
      user_id: userInfo.id,
      user_name: userInfo.name,
      archi_id: archiInfo.archi_id,
      archi_name: archiInfo.name,
      company_id: archiInfo.company_id,
      archi_type: archiInfo.archiType,
      section_id: archiInfo.sectionId,
      section_name: archiInfo.sectionName,
      now_date: nowData,
      now_datetime: nowDataTime,
      now_time: nowTime,
    }

    this.getFormValidate(this.formData.fields);
    if(this.moduleUuid && this.needFlow && this.formMode === 'add'){
      this.flowDesign({module:this.moduleUuid});
    }
    if (this.formData.baseLabel) {
      this.formLabel = '基本信息';
    }
    if (this.formData.tableStyle) {
      this.tableStyle = this.formData.tableStyle
    }
  },
  methods: parserMethods,
};
</script>

<style>
.el-form-item.is-error .el-input__inner,
.el-form-item.is-error .el-input__inner:focus,
.el-form-item.is-error .el-textarea__inner,
.el-form-item.is-error .el-textarea__inner:focus {
  border-color: #f56c6c !important;
}

.el-dialog__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  max-height: 100%;
}

.vertical-center.el-dialog {
  margin: 0 auto !important;
}

.vertical-center .el-dialog__body {
  padding: 15px 20px !important;
}

.el-dialog__body::-webkit-scrollbar,
.el-dialog__wrapper::-webkit-scrollbar,
.el-table__body-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  margin: 0 3px;
}

.el-dialog::-webkit-scrollbar-track,
.el-dialog__body::-webkit-scrollbar-track,
.el-table__body-wrapper::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.el-dialog::-webkit-scrollbar-thumb,
.el-dialog__body::-webkit-scrollbar-thumb,
.el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(189, 192, 199, 1);
  border-radius: 4px;
}

.el-dialog::-webkit-scrollbar-thumb:hover,
.el-dialog__body::-webkit-scrollbar-thumb:hover,
.el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: rgba(189, 192, 199, 1);
}

.el-dialog::-webkit-scrollbar-thumb:active,
.el-dialog__body::-webkit-scrollbar-thumb:active,
.el-table__body-wrapper::-webkit-scrollbar-thumb:active {
  background-color: rgba(189, 192, 199, 1);
}

.el-dialog::-webkit-scrollbar-corner,
.el-dialog__body::-webkit-scrollbar-corner,
.el-table__body-wrapper::-webkit-scrollbar-corner {
  background-color: #ffffff;
}
</style>

<style lang="less" scoped>
.parser-tp {
  margin-top: 20px;
}

// @import "@/main.less";
.type-label {
  border-left: 3px solid #007FE0 !important;
  padding: 0 10px;
  line-height: 20px;
  font-size: 14px;
  margin: 15px 0 !important;
  font-weight: 600;
  text-align: left;
}
:deep(.form-parser) {
  .table-wrapper,
  .table-relate {
    /*.el-form-item__content {
      .el-form-item {
        border: none;
      }
    }*/
    .el-table {
      .el-form-item {
        border: none;
      }
    }
  }
  .el-form-item {
    border: 1px solid #eaebed;
    display: flex;
    margin-bottom: -1px !important;
    /*margin-bottom: -1px !important;*/

    .el-form-item__label {
      border-right: 1px solid #eaebed;
      background: #f8f8f9;
      padding: 6px 10px;
    }

    .el-form-item__content {
      margin-left: 0 !important;
      flex: 1;
      padding: 6px 10px;
      overflow: hidden;
      clear: both;
      /*margin-bottom: 1px;*/

      .el-select,
      .el-cascader {
        width: 100%;
      }
      .el-input.is-disabled .el-input__inner{
        background-color: #ffffff;
      }
      .el-input-group__prepend,.el-input-group__append{
        border: none;
      }
      .el-input__inner,
      textarea {
        border: 1px solid transparent;
      }
    }
  }
}

:deep(.normal-parser) {
  .el-form-item__label {
    padding-left: 10px;
  }
  .el-form-item__content {
    margin-left: 0!important;
  }
  .el-select,
  .el-cascader {
    width: 100%;
  }

  .table-wrapper,
  .table-relate {
    margin-bottom: 18px;
  }
}

:deep(.common-dialog) {
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  .type-label {
    border-left: 3px solid #007fe0 !important;
    padding: 0 10px;
    line-height: 1;
    font-size: 14px;
    margin: 0 0 16px;
    font-weight: 600;
  }
  .dialog-main {
    overflow-x: auto;
  }
  .el-dialog__body{
    max-height: calc(100vh - 155px);
    height: calc(100vh - 155px);
    min-height: 300px;
    overflow: hidden;
    overflow-y: auto;
  }
}
</style>
